import React, { useEffect, useState } from "react";
import axios from "axios";

function App() {
  const [artists, setArtists] = useState([]);
  const [topTrack, setTopTrack] = useState([]);
  const [topAlbumImages, setTopAlbumImages] = useState([]);
  const [topGenreInfo, setTopGenreInfo] = useState([]);

  const [lastFinishTime, setLastFinishTime] = useState([]);

  useEffect(() => {
    // Fetch top track for the month
    axios
      .get("listen/v1/last_finish")
      .then((response) => {
        setLastFinishTime(response.data);
      })
      .catch((error) => {
        console.error("Error fetching last finish time: ", error);
      });
  }, []);

  useEffect(() => {
    // Fetch top track for the month
    axios
      .get("listen/v1/top_track")
      .then((response) => {
        setTopTrack(response.data);
      })
      .catch((error) => {
        console.error("Error fetching top track: ", error);
      });
  }, []);

  useEffect(() => {
    // Fetch top 10 artists from the Flask API
    axios
      .get("listen/v1/top_artists")
      .then((response) => {
        setArtists(response.data);
      })
      .catch((error) => {
        console.error("Error fetching top artists:", error);
      });
  }, []);

  useEffect(() => {
    // fetch top album images
    axios
      .get("listen/v1/top_album_images")
      .then((response) => {
        setTopAlbumImages(response.data);
      })
      .catch((error) => {
        console.error("Error fetching top album images:", error);
      });
  }, []);

  useEffect(() => {
    // fetch top genre info
    axios
      .get("listen/v1/top_genres")
      .then((response) => {
        setTopGenreInfo(response.data);
      })
      .catch((error) => {
        console.error("Error fetching top genre info:", error);
      });
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <h1>Top 10 Artists This Week</h1>
      {artists.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {artists.map((artist, index) => (
            <li key={index} style={{ margin: "10px 0" }}>
              <strong>
                {index + 1}.{" "}
                <a href={artist.artist_uri} style={{ color: "black" }}>
                  {artist.artist_name}
                </a>
              </strong>{" "}
              - {artist.count} plays
            </li>
          ))}
        </ul>
      )}

      <h1>Top Albums</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "0",
            width: "max-content",
          }}
        >
          {topAlbumImages.length === 0 ? (
            <p>Loading...</p>
          ) : (
            topAlbumImages.map((album, index) => (
              <div key={index} style={{ textAlign: "center" }}>
                <a href={album.album_uri}>
                  <img
                    src={album.album_image}
                    alt={`Album ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxWidth: "150px",
                      maxHeight: "150px",
                    }}
                  />
                </a>
              </div>
            ))
          )}
        </div>
      </div>

      <h1>Genres</h1>
      {topGenreInfo.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {topGenreInfo.map((genre, index) => (
            <li key={index} style={{ margin: "10px 0" }}>
              <p>
                I've been listening to <strong>{genre.genre_name}</strong> like{" "}
                {genre.artists.map((artist, artistIndex) => (
                  <span key={artistIndex}>
                    <strong>
                      <a href={artist.artist_uri} style={{ color: "black" }}>
                        {artist.artist_name}
                      </a>
                    </strong>
                    {artistIndex < genre.artists.length - 1 ? ", " : ""}
                  </span>
                ))}
              </p>
            </li>
          ))}
        </ul>
      )}

      <h1>My Top Track of the Month</h1>
      {topTrack.length === 0 ? (
        <p>Loading...</p>
      ) : (
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {topTrack.map((track, index) => (
            <li key={index} style={{ margin: "10px 0" }}>
              <strong>
                <a href={track.track_uri} style={{ color: "black" }}>
                  {track.track_name}
                </a>{" "}
                by {track.artist_name}
              </strong>
            </li>
          ))}
        </ul>
      )}

      <br />
      <br />
      {lastFinishTime.script_finished_at ? (
        <p>
          <i>
            Last Updated{" "}
            {new Date(lastFinishTime.script_finished_at)
              .toLocaleString("en-US", { timeZone: "America/New_York" })
              .replace(",", "")}{" "}
            EST
          </i>
        </p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default App;
